<template>
  <Content>
    <template #contentTitle>
      {{ $t("menu.print_waybill_create") }}
    </template>
    <template #contentBody>
      <div>
        <a-form
          ref="refForm"
          :rules="rules"
          :model="state"
          :label-col="{ span: 6 }"
          :wrapper-col="{
            sm: { span: 10 },
            xl: { span: 12 },
          }"
        >
          <a-row>
            <a-col
              :xs="{ span: 24 }"
              :sm="{ span: 24 }"
              :xl="{ span: 12 }"
              :xxl="{ span: 8 }"
            >
              <a-form-item
                name="destinationType"
                :label="$t('warehouse.destination_type')"
              >
                <a-select
                  v-model:value="state.destinationType"
                  @change="handleChangeDestinationType"
                  :placeholder="$t('warehouse.destination_type')"
                >
                  <a-select-option
                    v-for="(item, index) in dropdownData.destinationTypes"
                    :key="index"
                    :value="item"
                    :title="$t($enumLangkey('destinationType', item))"
                  >
                    {{ $t($enumLangkey("destinationType", item)) }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col
              :xs="{ span: 24 }"
              :sm="{ span: 24 }"
              :xl="{ span: 12 }"
              :xxl="{ span: 8 }"
            >
              <a-form-item
                name="customerNo"
                :label="$t('warehouse.customer_number')"
              >
                <a-input
                  autoComplete="new-password"
                  v-model:value="state.customerNo"
                  :placeholder="$t('warehouse.customer_number')"
                  allow-clear
                />
              </a-form-item>
            </a-col>
          </a-row>

          <!-- 发货地址 -------------------------------------->
          <a-row class="mb-2">
            <a-col
              :xs="{ span: 12 }"
              :sm="{ span: 24, offset: 4 }"
              :xl="{ span: 24, offset: 1 }"
              :xxl="{ span: 10, offset: 1 }"
            >
              <strong>{{ $t("warehouse.from_product_address") }}</strong>
            </a-col>
          </a-row>
          <a-row>
            <a-col
              :xs="{ span: 24 }"
              :sm="{ span: 24 }"
              :xl="{ span: 12 }"
              :xxl="{ span: 8 }"
            >
              <a-form-item :label="$t('warehouse.common_address')">
                <a-select
                  v-model:value="state.from.id"
                  @change="handleChangeSelectCommonAddressForm"
                  :loading="dropdownData.commonAddressLoading"
                  :allowClear="true"
                  show-search
                  optionFilterProp="search-key"
                  :placeholder="$t('warehouse.selected_common_address')"
                >
                  <a-select-option
                    v-for="item in dropdownData.commonAddress"
                    :key="item.id"
                    :value="item.id"
                    :countryId="item.countryId"
                    :provinceId="item.provinceId"
                    :cityId="item.cityId"
                    :countyId="item.countyId"
                    :address="item.address"
                    :address2="item.address2"
                    :postCode="item.postCode"
                    :companyName="item.companyName"
                    :telephone="item.telephone"
                    :telephoneCountryId="item.telephoneCountryId"
                    :commonAddressName="item.contactName"
                    :search-key="item.contactName"
                    :title="item.contactName"
                  >
                    {{ item.contactName }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col
              :xs="{ span: 24 }"
              :sm="{ span: 24 }"
              :xl="{ span: 12 }"
              :xxl="{ span: 8 }"
            >
              <a-form-item
                :label="$t('warehouse.originate')"
                :name="['from', 'name']"
              >
                <a-input
                  autoComplete="new-password"
                  v-model:value="state.from.name"
                  v-user-name-filter
                />
              </a-form-item>
            </a-col>
          </a-row>

          <a-row>
            <a-col
              :xs="{ span: 24 }"
              :sm="{ span: 24 }"
              :xl="{ span: 12 }"
              :xxl="{ span: 8 }"
            >
              <a-form-item
                :label="$t('common.postcode')"
                :name="['from', 'postCode']"
              >
                <a-input
                  autoComplete="new-password"
                  v-model:value="state.from.postCode"
                />
              </a-form-item>
            </a-col>

            <a-col
              :xs="{ span: 24 }"
              :sm="{ span: 24, offset: 6 }"
              :xl="{ span: 9, offset: 3 }"
              :xxl="{ span: 8, offset: 2 }"
            >
              <a-form-item>
                <a-button
                  :loading="state.saveCommonAddressLoading"
                  @click="handlePostCodeQuery('form')"
                  size="small"
                >
                  {{ $t("waybill.check_the_postcode") }}
                </a-button>
                <CPostQuery
                  v-model:visible="postCodeQueryData.visibleForm"
                  :postCode="state.from.postCode"
                  @onSelected="
                    (record) => handleOnPostQuerySelected(record, 'from')
                  "
                />
              </a-form-item>
            </a-col>
          </a-row>

          <a-row>
            <a-col :span="24">
              <a-form-item
                required
                class="mb-0"
                :label-col="{
                  sm: { span: 6 },
                  xl: { span: 3 },
                  xxl: { span: 2 },
                }"
                :wrapper-col="{
                  sm: { span: 18 },
                  xl: { span: 21 },
                  xxl: { span: 22 },
                }"
                :autoLink="false"
              >
                <template #label>
                  {{ $t("warehouse.from_product_address") }}
                </template>
                <a-row :gutter="16">
                  <a-col :span="24" :sm="8" :md="6" :xl="4" :xxl="3">
                    <a-form-item :name="['from', 'countryId']">
                      <a-select
                        v-model:value="state.from.countryId"
                        :allowClear="true"
                        show-search
                        optionFilterProp="search-key"
                        @change="handleChangeCountryForm"
                        class="mr-2 mb-1"
                        :loading="dropdownData.countryLoading"
                        :placeholder="$t('common.country')"
                      >
                        <a-select-option
                          v-for="item in dropdownData.countrysFrom"
                          :key="item.id"
                          :title="getLanguageName(item) + '(' + item.ioS2 + ')'"
                          :search-key="item.cnName + item.enName + item.ioS2"
                        >
                          {{ getLanguageName(item) }}({{ item.ioS2 }})
                        </a-select-option>
                      </a-select>
                    </a-form-item>
                  </a-col>
                  <a-col :span="24" :sm="8" :md="6" :xl="4" :xxl="3">
                    <a-form-item :name="['from', 'provinceId']">
                      <a-select
                        v-model:value="state.from.provinceId"
                        @change="handleChangeProvincesForm"
                        class="mr-2 mb-1"
                        :allowClear="true"
                        show-search
                        :loading="dropdownData.provincesLoading"
                        optionFilterProp="search-key"
                        :placeholder="$t('common.province')"
                      >
                        <a-select-option
                          v-for="item in dropdownData.provincesFrom"
                          :title="getLanguageName(item) + item.abbrCode"
                          :key="item.id"
                          :search-key="
                            item.cnName + item.enName + item.abbrCode
                          "
                        >
                          {{
                            getLanguageName(item) +
                            (item.abbrCode ? "(" + item.abbrCode + ")" : "")
                          }}
                        </a-select-option>
                      </a-select>
                    </a-form-item>
                  </a-col>
                  <a-col :span="24" :sm="8" :md="6" :xl="4" :xxl="3">
                    <a-form-item :name="['from', 'cityId']">
                      <a-select
                        v-model:value="state.from.cityId"
                        @change="handleChangeCityForm"
                        class="mr-2 mb-1"
                        :allowClear="true"
                        show-search
                        :loading="dropdownData.cityLoading"
                        optionFilterProp="search-key"
                        :placeholder="$t('common.city')"
                      >
                        <a-select-option
                          v-for="item in dropdownData.citysFrom"
                          :title="getLanguageName(item)"
                          :key="item.id"
                          :search-key="item.cnName + item.enName"
                        >
                          {{ getLanguageName(item) }}
                        </a-select-option>
                      </a-select>
                    </a-form-item>
                  </a-col>
                  <a-col
                    :span="24"
                    :sm="8"
                    :md="6"
                    :xl="4"
                    :xxl="3"
                    v-if="
                      dropdownData.countysFrom &&
                      dropdownData.countysFrom.length > 0
                    "
                  >
                    <a-form-item :name="['from', 'countyId']">
                      <a-select
                        v-model:value="state.from.countyId"
                        class="mr-2"
                        :allowClear="true"
                        show-search
                        :loading="dropdownData.countyLoading"
                        optionFilterProp="search-key"
                        :placeholder="$t('common.county')"
                      >
                        <a-select-option
                          v-for="item in dropdownData.countysFrom"
                          :title="getLanguageName(item)"
                          :key="item.id"
                          :search-key="item.cnName + item.enName"
                        >
                          {{ getLanguageName(item) }}
                        </a-select-option>
                      </a-select>
                    </a-form-item>
                  </a-col>
                </a-row>
              </a-form-item>
            </a-col>
          </a-row>

          <a-row>
            <a-col
              :xs="{ span: 24 }"
              :sm="{ span: 24 }"
              :xl="{ span: 12 }"
              :xxl="{ span: 8 }"
            >
              <a-form-item
                class="mb-2"
                :name="['from', 'address']"
                :label="$t('warehouse.detail_address')"
              >
                <a-input
                  autoComplete="new-password"
                  v-model:value="state.from.address"
                  :maxlength="35"
                  v-address-filter
                />
              </a-form-item>
            </a-col>
            <a-col
              :xs="{ span: 24 }"
              :sm="{ span: 24 }"
              :xl="{ span: 12 }"
              :xxl="{ span: 8 }"
            >
              <a-form-item
                class="mb-2"
                :label="$t('warehouse.detail_address') + 2"
              >
                <a-input
                  autoComplete="new-password"
                  v-model:value="state.from.address2"
                  :maxlength="35"
                  v-address-filter
                />
              </a-form-item>
            </a-col>
          </a-row>

          <a-row>
            <a-col
              :xs="{ span: 24 }"
              :sm="{ span: 24, offset: 6 }"
              :xl="{ span: 24, offset: 15 }"
              :xxl="{ span: 24, offset: 10 }"
            >
              <a-form-item class="mb-0 mt-0">
                <a-button
                  @click="handleSaveCommonAddressForm"
                  :loading="state.saveCommonAddressLoading"
                  size="small"
                >
                  <span v-if="!state.from.id">
                    {{ $t("warehouse.save_as_common_address") }}
                  </span>
                  <span v-else>
                    {{ $t("warehouse.refresh_address") }}
                  </span>
                </a-button>
              </a-form-item>
            </a-col>
          </a-row>

          <!-- 收货地址 ---------------------------------------------------------------------->

          <template
            v-if="state.destinationType == destinationTypeEnum.OtherAddress"
          >
            <a-row class="mb-2">
              <a-col
                :xs="{ span: 12 }"
                :sm="{ span: 24, offset: 4 }"
                :xl="{ span: 24, offset: 1 }"
                :xxl="{ span: 10, offset: 1 }"
              >
                <strong>{{ $t("warehouse.receiving_address") }}</strong>
              </a-col>
            </a-row>
            <a-row>
              <a-col
                :xs="{ span: 24 }"
                :sm="{ span: 24 }"
                :xl="{ span: 12 }"
                :xxl="{ span: 8 }"
              >
                <a-form-item :label="$t('warehouse.common_address')">
                  <a-select
                    v-model:value="state.toPersonal.id"
                    @change="handleChangeSelectCommonAddress"
                    :loading="dropdownData.commonAddressLoading"
                    :allowClear="true"
                    show-search
                    optionFilterProp="search-key"
                    :placeholder="$t('warehouse.selected_common_address')"
                  >
                    <a-select-option
                      v-for="item in dropdownData.commonAddress"
                      :key="item.id"
                      :value="item.id"
                      :countryId="item.countryId"
                      :provinceId="item.provinceId"
                      :cityId="item.cityId"
                      :countyId="item.countyId"
                      :address="item.address"
                      :address2="item.address2"
                      :postCode="item.postCode"
                      :companyName="item.companyName"
                      :telephone="item.telephone"
                      :telephoneCountryId="item.telephoneCountryId"
                      :commonAddressName="item.contactName"
                      :search-key="item.contactName"
                      :title="item.contactName"
                    >
                      {{ item.contactName }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>

              <a-col
                :xs="{ span: 24 }"
                :sm="{ span: 24 }"
                :xl="{ span: 12 }"
                :xxl="{ span: 8 }"
              >
                <a-form-item
                  :label="$t('warehouse.recipients')"
                  :name="['toPersonal', 'name']"
                >
                  <a-input
                    autoComplete="new-password"
                    v-model:value="state.toPersonal.name"
                    v-user-name-filter
                  />
                </a-form-item>
              </a-col>
            </a-row>

            <a-row>
              <a-col
                :xs="{ span: 24 }"
                :sm="{ span: 24 }"
                :xl="{ span: 12 }"
                :xxl="{ span: 8 }"
              >
                <a-form-item :label="$t('warehouse.recipient_company')">
                  <a-input v-model:value="state.toPersonal.companyName" />
                </a-form-item>
              </a-col>

              <a-col
                :xs="{ span: 24 }"
                :sm="{ span: 24 }"
                :xl="{ span: 12 }"
                :xxl="{ span: 8 }"
              >
                <a-form-item
                  :label="$t('warehouse.phone')"
                  :name="['toPersonal', 'telephone']"
                >
                  <a-input-group style="display: flex" compact>
                    <a-select
                      style="width: 100px"
                      v-model:value="state.toPersonal.telephoneCountryId"
                      :placeholder="$t('logistics.area_code')"
                      :loading="dropdownData.phoneCountryLoading"
                      option-label-prop="label"
                      optionFilterProp="search-key"
                      show-search
                      @change="handleCountryCodeChange"
                    >
                      <a-select-option
                        v-for="(item, index) in dropdownData.phoneCountryList"
                        :key="index"
                        :value="item.id"
                        :label="`+${item.telCode}`"
                        :search-key="
                          item.ioS2 +
                          item.cnName +
                          item.enName +
                          '+' +
                          item.telCode
                        "
                        :title="`+${item.telCode}(${getLanguageName(item)})`"
                        >+{{ item.telCode }}({{
                          getLanguageName(item)
                        }})</a-select-option
                      >
                    </a-select>
                    <a-input
                      style="flex: 1"
                      autoComplete="new-password"
                      v-model:value="state.toPersonal.telephone"
                      :placeholder="$t('warehouse.phone')"
                      @change="handleCountryCodeChange"
                    />
                  </a-input-group>
                </a-form-item>
              </a-col>
            </a-row>

            <a-row>
              <a-col
                :xs="{ span: 24 }"
                :sm="{ span: 24 }"
                :xl="{ span: 12 }"
                :xxl="{ span: 8 }"
              >
                <a-form-item
                  :label="$t('common.postcode')"
                  :name="['toPersonal', 'postCode']"
                >
                  <a-input
                    autoComplete="new-password"
                    v-model:value="state.toPersonal.postCode"
                  />
                </a-form-item>
              </a-col>

              <a-col
                :xs="{ span: 24 }"
                :sm="{ span: 24, offset: 6 }"
                :xl="{ span: 9, offset: 3 }"
                :xxl="{ span: 8, offset: 2 }"
              >
                <a-form-item>
                  <a-button
                    :loading="state.saveCommonAddressLoading"
                    @click="handlePostCodeQuery('toPersonal')"
                    size="small"
                  >
                    {{ $t("waybill.check_the_postcode") }}
                  </a-button>
                  <CPostQuery
                    v-model:visible="postCodeQueryData.visible"
                    :postCode="state.toPersonal.postCode"
                    @onSelected="
                      (record) =>
                        handleOnPostQuerySelected(record, 'toPersonal')
                    "
                  />
                </a-form-item>
              </a-col>
            </a-row>

            <a-row>
              <a-col :span="24">
                <a-form-item
                  required
                  class="mb-0"
                  :label-col="{
                    sm: { span: 6 },
                    xl: { span: 3 },
                    xxl: { span: 2 },
                  }"
                  :wrapper-col="{
                    sm: { span: 18 },
                    xl: { span: 21 },
                    xxl: { span: 22 },
                  }"
                  :autoLink="false"
                >
                  <template #label>
                    {{ $t("warehouse.receiving_address") }}
                  </template>
                  <a-row :gutter="16">
                    <a-col :span="24" :sm="8" :md="6" :xl="4" :xxl="3">
                      <a-form-item :name="['toPersonal', 'countryId']">
                        <a-select
                          v-model:value="state.toPersonal.countryId"
                          :allowClear="true"
                          show-search
                          optionFilterProp="search-key"
                          @change="handleChangeCountry"
                          class="mr-2 mb-1"
                          :loading="dropdownData.countryLoading"
                          :placeholder="$t('common.country')"
                        >
                          <a-select-option
                            v-for="item in dropdownData.countrys"
                            :key="item.id"
                            :title="
                              getLanguageName(item) + '(' + item.ioS2 + ')'
                            "
                            :search-key="item.cnName + item.enName + item.ioS2"
                          >
                            {{ getLanguageName(item) }}({{ item.ioS2 }})
                          </a-select-option>
                        </a-select>
                      </a-form-item>
                    </a-col>
                    <a-col :span="24" :sm="8" :md="6" :xl="4" :xxl="3">
                      <a-form-item :name="['toPersonal', 'provinceId']">
                        <a-select
                          v-model:value="state.toPersonal.provinceId"
                          @change="handleChangeProvinces"
                          class="mr-2 mb-1"
                          :allowClear="true"
                          show-search
                          :loading="dropdownData.provincesLoading"
                          optionFilterProp="search-key"
                          :placeholder="$t('common.province')"
                        >
                          <a-select-option
                            v-for="item in dropdownData.provinces"
                            :title="getLanguageName(item) + item.abbrCode"
                            :key="item.id"
                            :search-key="
                              item.cnName + item.enName + item.abbrCode
                            "
                          >
                            {{
                              getLanguageName(item) +
                              (item.abbrCode ? "(" + item.abbrCode + ")" : "")
                            }}
                          </a-select-option>
                        </a-select>
                      </a-form-item>
                    </a-col>
                    <a-col :span="24" :sm="8" :md="6" :xl="4" :xxl="3">
                      <a-form-item :name="['toPersonal', 'cityId']">
                        <a-select
                          v-model:value="state.toPersonal.cityId"
                          @change="handleChangeCity"
                          class="mr-2 mb-1"
                          :allowClear="true"
                          show-search
                          :loading="dropdownData.cityLoading"
                          optionFilterProp="search-key"
                          :placeholder="$t('common.city')"
                        >
                          <a-select-option
                            v-for="item in dropdownData.citys"
                            :title="getLanguageName(item)"
                            :key="item.id"
                            :search-key="item.cnName + item.enName"
                          >
                            {{ getLanguageName(item) }}
                          </a-select-option>
                        </a-select>
                      </a-form-item>
                    </a-col>
                    <a-col
                      :span="24"
                      :sm="8"
                      :md="6"
                      :xl="4"
                      :xxl="3"
                      v-if="
                        dropdownData.countys && dropdownData.countys.length > 0
                      "
                    >
                      <a-form-item :name="['toPersonal', 'countyId']">
                        <a-select
                          v-model:value="state.toPersonal.countyId"
                          class="mr-2"
                          :allowClear="true"
                          show-search
                          :loading="dropdownData.countyLoading"
                          optionFilterProp="search-key"
                          :placeholder="$t('common.county')"
                        >
                          <a-select-option
                            v-for="item in dropdownData.countys"
                            :title="getLanguageName(item)"
                            :key="item.id"
                            :search-key="item.cnName + item.enName"
                          >
                            {{ getLanguageName(item) }}
                          </a-select-option>
                        </a-select>
                      </a-form-item>
                    </a-col>
                  </a-row>
                </a-form-item>
              </a-col>
            </a-row>

            <a-row>
              <a-col
                :xs="{ span: 24 }"
                :sm="{ span: 24 }"
                :xl="{ span: 12 }"
                :xxl="{ span: 8 }"
              >
                <a-form-item
                  class="mb-2"
                  :name="['toPersonal', 'address']"
                  :label="$t('warehouse.detail_address')"
                >
                  <a-input
                    autoComplete="new-password"
                    v-model:value="state.toPersonal.address"
                    :maxlength="35"
                    v-address-filter
                  />
                </a-form-item>
              </a-col>
              <a-col
                :xs="{ span: 24 }"
                :sm="{ span: 24 }"
                :xl="{ span: 12 }"
                :xxl="{ span: 8 }"
              >
                <a-form-item
                  class="mb-2"
                  :label="$t('warehouse.detail_address') + 2"
                >
                  <a-input
                    autoComplete="new-password"
                    v-model:value="state.toPersonal.address2"
                    :maxlength="35"
                    v-address-filter
                  />
                </a-form-item>
              </a-col>
            </a-row>

            <a-row>
              <a-col
                :xs="{ span: 24 }"
                :sm="{ span: 24, offset: 6 }"
                :xl="{ span: 24, offset: 15 }"
                :xxl="{ span: 24, offset: 10 }"
              >
                <a-form-item class="mb-0 mt-0">
                  <a-button
                    @click="handleSaveCommonAddress"
                    :loading="state.saveCommonAddressLoading"
                    size="small"
                  >
                    <span v-if="!state.toPersonal.id">
                      {{ $t("warehouse.save_as_common_address") }}
                    </span>
                    <span v-else>
                      {{ $t("warehouse.refresh_address") }}
                    </span>
                  </a-button>
                </a-form-item>
              </a-col>
            </a-row>
          </template>

          <template v-else>
            <a-row>
              <a-col
                :xs="{ span: 24 }"
                :sm="{ span: 24 }"
                :xl="{ span: 12 }"
                :xxl="{ span: 8 }"
              >
                <a-form-item
                  name="toWarehoueOrFbaOrMcdId"
                  :label="$t('warehouse.warehouse_code')"
                >
                  <a-select
                    v-model:value="state.toWarehoueOrFbaOrMcdId"
                    @change="handleChangeWarehouseCodes"
                    :loading="dropdownData.warehouseCodesLoading"
                    :allowClear="true"
                    show-search
                    optionFilterProp="search-key"
                    :placeholder="$t('warehouse.warehouse_code')"
                  >
                    <!-- se 枚举值==1-->
                    <template
                      v-if="
                        state.destinationType == destinationTypeEnum.SeWarehouse
                      "
                    >
                      <a-select-option
                        v-for="(item, index) in dropdownData.warehouseCodes"
                        :key="index"
                        :info="item"
                        :code="item.warehouseNo"
                        :value="item.id"
                        :search-key="item.warehouseNo + item.warehouseName"
                        :title="
                          item.warehouseNo + '(' + item.warehouseName + ')'
                        "
                      >
                        {{ item.warehouseNo
                        }}<span v-if="item.warehouseName">
                          ({{ item.warehouseName }})</span
                        >
                      </a-select-option>
                    </template>
                    <!-- fba枚举值 ==2-->
                    <template
                      v-if="state.destinationType == destinationTypeEnum.FBA"
                    >
                      <a-select-option
                        v-for="(item, index) in dropdownData.warehouseCodes"
                        :key="index"
                        :code="item.name"
                        :info="item"
                        :value="item.id"
                        :search-key="item.name"
                        :title="item.name"
                      >
                        {{ item.name }}
                      </a-select-option>
                    </template>
                  </a-select>
                </a-form-item>
              </a-col>
            </a-row>

            <a-row v-if="state.toWarehoueOrFbaOrMcdId">
              <a-col
                :xs="{ span: 24 }"
                :sm="{ span: 16, offset: 6 }"
                :xl="{ span: 20, offset: 3 }"
                :xxl="{ span: 20, offset: 2 }"
                class="mb-3"
              >
                <a-row :gutter="[60]">
                  <a-col>
                    {{ $t("warehouse.recipients") }}: {{ state.showRecipients }}
                  </a-col>
                  <a-col>
                    {{ $t("warehouse.recipient_company") }}:
                    {{ state.showRecipientsCompany }}
                  </a-col>
                  <a-col :span="24">
                    {{ $t("warehouse.receive_goods_address") }}:
                    {{ getAddress(state.showRecipientsAddress || {}) }}
                  </a-col>
                </a-row>
              </a-col>
            </a-row>
          </template>
          <a-row>
            <a-col
              :xs="{ span: 24 }"
              :sm="{ span: 24 }"
              :xl="{ span: 12 }"
              :xxl="{ span: 8 }"
            >
              <a-form-item
                name="tranPlanCharacteristics"
                :label="$t('warehouse.transport_characteristics')"
              >
                <a-select
                  v-model:value="state.tranPlanCharacteristics"
                  mode="multiple"
                  :allowClear="true"
                  :placeholder="$t('warehouse.transport_characteristics')"
                >
                  <a-select-option
                    v-for="(item, index) in transportCharacteristicsEnum"
                    :key="index"
                    :value="item"
                    :title="$t($enumLangkey('transportCharacteristics', item))"
                  >
                    {{ $t($enumLangkey("transportCharacteristics", item)) }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
      </div>

      <div>
        <a-button @click="handleShowModal" type="primary">{{
          $t("warehouse.add_box")
        }}</a-button>
      </div>
      <div>
        <a-table
          :columns="columns"
          size="small"
          :data-source="state.list"
          :scroll="{ x: 400 }"
          :rowKey="
            (record, index) => {
              return index;
            }
          "
          :pagination="false"
          class="product-table"
        >
          <template #weight="{ record }">
            {{ gToKg(record.weight) }} kg
          </template>
          <template #size="{ record }">
            {{ record.length }} x {{ record.width }} x {{ record.height }} cm
          </template>
          <template #girth="{ record }">
            {{
              roundNumber(record.length + 2 * (record.width + record.height))
            }}
            cm
          </template>
          <template #volume="{ record }">
            {{
              roundNumber(
                (record.length / 100) *
                  (record.width / 100) *
                  (record.height / 100),
                6
              )
            }}
            m<sup>3</sup>
          </template>
          <template #operate="{ record }">
            <a-button @click="handleRemove(record)">{{
              $t("common.remove")
            }}</a-button>
          </template>
        </a-table>
      </div>
      <div>
        <a-row type="flex" justify="end">
          <a-col>
            <a-button
              @click="handleCreate"
              :loading="state.loading"
              class="mt-3 mr-5"
              type="primary"
              >{{ $t("common.next_step") }}</a-button
            >
          </a-col>
        </a-row>
      </div>

      <a-modal
        v-model:visible="addBoxModal.visible"
        :title="$t('warehouse.add_single_box')"
        :cancelText="$t('common.close')"
        :okText="$t('common.confirm')"
        :confirm-loading="addBoxModal.loading"
        :maskClosable="false"
        @ok="handleAddBox"
        :centered="true"
      >
        <a-row :gutter="[0, 8]">
          <a-col :span="24">
            <a-row type="flex" :gutter="[0, 4]">
              <a-col :span="24">
                <span>{{ $t("warehouse.weight") }}: </span>
                <a-input-number
                  v-model:value="addBoxModal.weight"
                  :precision="3"
                  :placeholder="$t('warehouse.weight')"
                  class="ml-2"
                  :min="0"
                />
                kg
              </a-col>

              <a-col>
                <span>{{ $t("warehouse.size") }}: </span>
                <a-input-number
                  v-model:value="addBoxModal.length"
                  :step="1"
                  :precision="0"
                  class="ml-2 mt-2"
                  :placeholder="$t('common.max_length_side')"
                  :min="0"
                />
                <a-input-number
                  v-model:value="addBoxModal.width"
                  :step="1"
                  :precision="0"
                  class="ml-2 mt-2"
                  :placeholder="$t('common.second_length_side')"
                  :min="0"
                />
                <a-input-number
                  v-model:value="addBoxModal.height"
                  :step="1"
                  :precision="0"
                  class="ml-2 mt-2"
                  :placeholder="$t('common.least_length_side')"
                  :min="0"
                />
                cm
              </a-col>
              <a-col>
                <span>{{ $t("warehouse.box_amount") }}: </span>
                <a-input-number
                  v-model:value="addBoxModal.count"
                  class="ml-2 mt-2"
                  :step="1"
                  :precision="0"
                  :placeholder="$t('common.box_amount')"
                  :min="1"
                  :max="50"
                />
              </a-col>
            </a-row>
          </a-col>
        </a-row>
      </a-modal>
    </template>
  </Content>
</template>

<script>
import { useI18n } from "vue-i18n/index";
import { h, reactive, onMounted, onActivated, ref, watch } from "vue";
import {
  Row,
  Col,
  Dropdown,
  Menu,
  Table,
  Input,
  AutoComplete,
  Select,
  Button,
  Form,
  InputNumber,
  Modal,
  message,
  Space,
} from "ant-design-vue";
import Content from "../components/Content.vue";
import { getGlobalCountrys } from "@/api/modules/common/index";
import {
  getDetails,
  create,
  update,
  getSourceCountrys,
  getSeTargetWarehouseInfos,
  getFbaTargetWarehouseInfos,
} from "../../api/modules/printWaybill/create";
import {
  transportCharacteristics as transportCharacteristicsEnum,
  destinationType as destinationTypeEnum,
} from "../../enum/enum.json";
import { useRouter, useRoute } from "vue-router";
import {
  gToKg,
  kgToG,
  roundNumber,
  getAddressByLanguageV2,
  getName,
  replaceBrackets,
  verifyMobilePhoneNumber,
} from "../../utils/general";
import Address from "./Address.vue";
import { useStore } from "vuex";
import {
  getCommonAddress,
  createCommonAddress,
  updateCommonAddress,
} from "../../api/modules/common/index";
import { ExclamationCircleOutlined } from "@ant-design/icons-vue";
import { useTab } from "../../hooks/tabs/index";
import { cloneDeep } from "lodash-es";
import CPostQuery from "../components/CPostQuery.vue";
import { useNewAddrHelper } from "../../utils/addressHelper";

export default {
  name: "print_waybill_create",
  components: {
    Address,
    Content,
    ARow: Row,
    ACol: Col,
    ADropdown: Dropdown,
    AMenu: Menu,
    AMenuItem: Menu.Item,
    AAutoComplete: AutoComplete,
    ATable: Table,
    AInput: Input,
    AInputNumber: InputNumber,
    AInputGroup: Input.Group,
    ASelect: Select,
    ASelectOption: Select.Option,
    AButton: Button,
    AFormItem: Form.Item,
    AForm: Form,
    AModal: Modal,
    ASpace: Space,
    CPostQuery: CPostQuery,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();

    const refForm = ref();
    const { getters } = useStore();
    const { delVisitedRoute } = useTab();

    const i18n = useI18n({ useScope: "global" });
    const addrHelper = useNewAddrHelper();
    const state = reactive({
      orderId: null,
      formId: route.query?.fromId,
      customerNo: null,
      destinationType: destinationTypeEnum.SeWarehouse,
      tranPlanCharacteristics: [],
      toWarehoueOrFbaOrMcdId: null,
      from: {
        name: null,
        countryId: null,
        provinceId: null,
        cityId: null,
        countyId: null,
        address: null,
        address2: null,
        postCode: null,
        id: null,//公共地址id
      },
      toPersonal: {
        //目的地址
        companyName: null,
        telephone: null,
        name: null,
        countryId: null,
        provinceId: null,
        cityId: null,
        countyId: null,
        address: null,
        address2: null,
        postCode: null,
        id: null,
      },
      details: [
        {
          length: null,
          width: null,
          height: null,
          weight: null,
        },
      ],
      list: [],
      loading: false,

      //赛易和fba 显示的收货地址
      showRecipients: null,
      showRecipientsCompany: null,
      showRecipientsAddress: null,

      saveCommonAddressLoading: false,
    });

    const dropdownData = reactive({
      sourceCountrys: [],
      sourceCountryLoading: false,
      warehouses: [],
      warehouseLoading: false,
      destinationTypes: [
        destinationTypeEnum.SeWarehouse,
        destinationTypeEnum.FBA,
        destinationTypeEnum.OtherAddress,
      ],
      commonAddress: [],
      commonAddressLoading: false,
      countrys: [],
      countryLoading: false,
      provinces: [],
      provincesLoading: false,
      citys: [],
      cityLoading: false,
      countys: [],
      countyLoading: false,

      warehouseCodes: [],
      warehouseCodesLoading: false,
      seWarehouseCodes: [],
      fbaWarehouseCodes: [],

      phoneCountryLoading: false,
      phoneCountryList: [],
    });

    //始发国家
    const funcGetSourceCountrys = () => {
      dropdownData.sourceCountryLoading = true;
      getSourceCountrys()
        .then(({ result }) => {
          if (result) {
            dropdownData.sourceCountrys = result;
          }
          dropdownData.sourceCountryLoading = false;
        })
        .catch(() => {
          dropdownData.sourceCountryLoading = false;
        });
    };

    const getCountriesList = () => {
      dropdownData.phoneCountryLoading = true;
      getGlobalCountrys()
        .then(({ result }) => {
          dropdownData.phoneCountryList = result;
        })
        .catch(() => {})
        .finally(() => {
          dropdownData.phoneCountryLoading = false;
        });
    };

    const checkPhoneNumber = () => {
      state.toPersonal.telephone = replaceBrackets(state.toPersonal.telephone);
    };

    const handleCountryCodeChange = () => {
      checkPhoneNumber();
      refForm.value.validateFields([["toPersonal", "telephone"]]);
    };

    const getLanguageName = (item) => {
      if (item.countryCnName && item.countryEnName) {
        return getName(
          {
            cnName: item.countryCnName,
            enName: item.countryEnName,
          },
          getters.language
        );
      }
      return getName(item, getters.language);
    };

    //--------------添加箱----------------------------------
    const addBoxModal = reactive({
      visible: false,
      loading: false,
    });

    const getAddBoxModal = () => {
      return {
        key: null,
        length: null,
        width: null,
        height: null,
        weight: null,
        count: 1,
      };
    };

    const handleShowModal = () => {
      Object.assign(addBoxModal, getAddBoxModal());
      addBoxModal.visible = true;
    };

    const handleRemove = (record) => {
      let temp = state.list.filter((item) => item.key != record.key);
      temp.forEach((x, index) => (x.key = index + 1));
      state.list = temp;
      message.info(i18n.t("common.remove_success"));
    };

    //---------------modal 相关操作 开始----
    const handleAddBox = () => {
      if (!addBoxModal.weight || addBoxModal.weight == 0) {
        return message.error(
          i18n.t("common.p0_cannot_be_empty_or_0", [i18n.t("warehouse.weight")])
        );
      }

      if (
        !addBoxModal.length ||
        addBoxModal.length == 0 ||
        !addBoxModal.width ||
        addBoxModal.width == 0 ||
        !addBoxModal.height ||
        addBoxModal.height == 0
      ) {
        return message.error(
          i18n.t("common.p0_cannot_be_empty_or_0", [i18n.t("warehouse.size")])
        );
      }

      if (
        addBoxModal.length < addBoxModal.width ||
        addBoxModal.length < addBoxModal.height ||
        addBoxModal.width < addBoxModal.height
      ) {
        handleShowConfim();
        return;
      }
      funcModalToTable();
    };

    const handleShowConfim = () => {
      Modal.confirm({
        title: i18n.t("common.current_size_fill_in_error"),
        icon: h(ExclamationCircleOutlined),
        content: i18n.t("common.system_automatically_helps_repair"),
        centered: true,
        mask: true,
        maskClosable: false,
        keyboard: false,
        okText: i18n.t("common.confirm"),
        cancelText: i18n.t("common.cancel"),
        onOk() {
          funcCorrectionModalSize();
          message.success(i18n.t("common.data_modification_succeeded"));
        },
      });
    };

    const funcModalToTable = () => {
      let tempList = [];
      for (let index = 0; index < addBoxModal.count; index++) {
        addBoxModal.key = state.list?.length + tempList.length ?? 1;
        let temp = cloneDeep(addBoxModal);
        temp.weight = kgToG(temp.weight);
        tempList.push(temp);
      }
      state.list = state.list.concat(tempList);
      addBoxModal.visible = false;
      message.info(i18n.t("common.add_success"));
    };

    const funcCorrectionModalSize = () => {
      let tempArray = [
        addBoxModal.length,
        addBoxModal.width,
        addBoxModal.height,
      ];
      //降序
      tempArray.sort((a, b) => b - a);
      addBoxModal.length = tempArray[0];
      addBoxModal.width = tempArray[1];
      addBoxModal.height = tempArray[2];
    };

    //---------------modal 相关操作 end----

    //--------发货 地址相关的操作 开始--------------------
    const handleChangeSelectCommonAddressForm = (value, option) => {
      if (!value) {
        return;
      }
      state.from.countryId = option.countryId ?? "";
      state.from.provinceId = option.provinceId ?? "";
      state.from.cityId = option.cityId ?? "";
      state.from.countyId = option.countyId ?? "";
      handleChangeCountryForm();

      state.from.address = option.address ?? "";
      state.from.address2 = option.address2 ?? "";
      state.from.postCode = option.postCode ?? "";
      state.from.name = option.commonAddressName ?? "";

      refForm.value?.clearValidate();
    };

    const handleChangeCountryForm = () => {
      funcGetProvincesForm();
    };

    const handleChangeProvincesForm = () => {
      funcGetCitysForm();
    };

    const handleChangeCityForm = () => {
      funcGetCountysForm();
    };

    const funcGetCountrysForm = () => {
      dropdownData.countryLoading = true;
      addrHelper
        .getCountries()
        .then((res) => {
          dropdownData.countrysFrom = res;
        })
        .finally(() => {
          dropdownData.countryLoading = false;
          if (
            state.from.countryId &&
            dropdownData.countrysFrom.findIndex(
              (x) => x.id == state.from.countryId
            ) == -1
          ) {
            state.from.countryId = null;
          }
          funcGetProvincesForm();
        });
    };

    const funcGetProvincesForm = async () => {
      dropdownData.provincesLoading = true;
      addrHelper
        .getProvinces(state.from.countryId)
        .then((res) => {
          dropdownData.provincesFrom = res;
        })
        .finally(() => {
          dropdownData.provincesLoading = false;
          if (
            state.from.provinceId &&
            dropdownData.provincesFrom.findIndex(
              (x) => x.id == state.from.provinceId
            ) == -1
          ) {
            state.from.provinceId = null;
          }
          funcGetCitysForm();
        });
    };

    const funcGetCitysForm = () => {
      dropdownData.cityLoading = true;
      addrHelper
        .getCities(state.from.provinceId)
        .then((res) => {
          dropdownData.citysFrom = res;
        })
        .finally(() => {
          dropdownData.cityLoading = false;
          if (
            state.from.cityId &&
            dropdownData.citysFrom.findIndex(
              (x) => x.id == state.from.cityId
            ) == -1
          ) {
            state.from.cityId = null;
          }
          funcGetCountysForm();
        });
    };

    const funcGetCountysForm = () => {
      dropdownData.countyLoading = true;
      addrHelper
        .getCounties(state.from.cityId)
        .then((res) => {
          dropdownData.countysFrom = res;
        })
        .finally(() => {
          dropdownData.countyLoading = false;
          if (
            state.from.countyId &&
            dropdownData.countysFrom.findIndex(
              (x) => x.id == state.from.countyId
            ) == -1
          ) {
            state.from.countyId = null;
          }
        });
    };

    const handleSaveCommonAddressForm = () => {
      state.saveCommonAddressLoading = true;
      refForm.value
        .validateFields([
          ["from", "name"],
          ["from", "countryId"],
          ["from", "provinceId"],
          ["from", "cityId"],
          ["from", "countyId"],
          ["from", "address"],
          ["from", "postCode"],
        ])
        .then(() => {
          let parameter = {
            contactName: state.from.name,
            ...state.from,
          };
          if (parameter.id) {
            return updateCommonAddress(parameter).then(() => {
              message.success(i18n.t("common.succeed"));
              hanldeGetCommonAddress();
              state.saveCommonAddressLoading = false;
            });
          } else {
            return createCommonAddress(parameter).then((res) => {
              message.success(i18n.t("common.succeed"));
              hanldeGetCommonAddress();
              state.from.id = res.result;
              state.saveCommonAddressLoading = false;
            });
          }
        })
        .catch((err) => {
          state.saveCommonAddressLoading = false;
        });
    };

    //--------发货 地址相关的操作 结束---------------------------

    //--------收货 地址相关的操作 开始--------------------

    const hanldeGetCommonAddress = () => {
      dropdownData.commonAddressLoading = true;
      getCommonAddress()
        .then(({ result }) => {
          if (result) {
            dropdownData.commonAddress = result;
          }
          dropdownData.commonAddressLoading = false;
        })
        .catch(() => {
          dropdownData.commonAddressLoading = false;
        });
    };

    const handleChangeSelectCommonAddress = (value, option) => {
      if (!value) {
        return;
      }
      state.toPersonal.countryId = option.countryId ?? "";
      state.toPersonal.provinceId = option.provinceId ?? "";
      state.toPersonal.cityId = option.cityId ?? "";
      state.toPersonal.countyId = option.countyId ?? "";
      handleChangeCountry();

      state.toPersonal.address = option.address ?? "";
      state.toPersonal.address2 = option.address2 ?? "";
      state.toPersonal.postCode = option.postCode ?? "";
      state.toPersonal.name = option.commonAddressName ?? "";
      state.toPersonal.companyName = option.companyName ?? "";
      state.toPersonal.telephone = option.telephone ?? "";
      state.toPersonal.telephoneCountryId = option.telephoneCountryId ?? "";
      refForm.value?.clearValidate();
    };

    const handleChangeCountry = () => {
      funcGetProvinces();
    };

    const handleChangeProvinces = () => {
      funcGetCitys();
    };

    const handleChangeCity = () => {
      funcGetCountys();
    };

    const funcGetCountrys = () => {
      dropdownData.countryLoading = true;
      addrHelper
        .getCountries()
        .then((res) => {
          dropdownData.countrys = res;
        })
        .finally(() => {
          dropdownData.countryLoading = false;
          if (
            state.toPersonal.countryId &&
            dropdownData.countrys.findIndex(
              (x) => x.id == state.toPersonal.countryId
            ) == -1
          ) {
            state.toPersonal.countryId = null;
          }
          funcGetProvinces();
        });
    };

    const funcGetProvinces = async () => {
      dropdownData.provincesLoading = true;
      addrHelper
        .getProvinces(state.toPersonal.countryId)
        .then((res) => {
          dropdownData.provinces = res;
        })
        .finally(() => {
          dropdownData.provincesLoading = false;
          if (
            state.toPersonal.provinceId &&
            dropdownData.provinces.findIndex(
              (x) => x.id == state.toPersonal.provinceId
            ) == -1
          ) {
            state.toPersonal.provinceId = null;
          }
          funcGetCitys();
        });
    };

    const funcGetCitys = () => {
      dropdownData.cityLoading = true;
      addrHelper
        .getCities(state.toPersonal.provinceId)
        .then((res) => {
          dropdownData.citys = res;
        })
        .finally(() => {
          dropdownData.cityLoading = false;
          if (
            state.toPersonal.cityId &&
            dropdownData.citys.findIndex(
              (x) => x.id == state.toPersonal.cityId
            ) == -1
          ) {
            state.toPersonal.cityId = null;
          }
          funcGetCountys();
        });
    };

    const funcGetCountys = () => {
      dropdownData.countyLoading = true;
      addrHelper
        .getCounties(state.toPersonal.cityId)
        .then((res) => {
          dropdownData.countys = res;
        })
        .finally(() => {
          dropdownData.countyLoading = false;
          if (
            state.toPersonal.countyId &&
            dropdownData.countys.findIndex(
              (x) => x.id == state.toPersonal.countyId
            ) == -1
          ) {
            state.toPersonal.countyId = null;
          }
        });
    };

    const handleSaveCommonAddress = () => {
      state.saveCommonAddressLoading = true;
      refForm.value
        .validateFields([
          ["toPersonal", "name"],
          ["toPersonal", "countryId"],
          ["toPersonal", "provinceId"],
          ["toPersonal", "cityId"],
          ["toPersonal", "countyId"],
          ["toPersonal", "address"],
          ["toPersonal", "postCode"],
        ])
        .then(() => {
          let parameter = {
            contactName: state.toPersonal.name,
            containerTelephone: true,
            ...state.toPersonal,
          };
          if (parameter.id) {
            return updateCommonAddress(parameter).then(() => {
              message.success(i18n.t("common.succeed"));
              hanldeGetCommonAddress();
              state.saveCommonAddressLoading = false;
            });
          } else {
            return createCommonAddress(parameter).then((res) => {
              message.success(i18n.t("common.succeed"));
              hanldeGetCommonAddress();
              state.toPersonal.id = res.result;
              state.saveCommonAddressLoading = false;
            });
          }
        })
        .catch((err) => {
          state.saveCommonAddressLoading = false;
        });
    };

    //-------- 收货 地址相关的操作 结束---------------------------
    const rules = {
      destinationType: [
        {
          required: true,
          type: "number",
          trigger: ["change", "blur"],
          message: () => {
            return i18n.t("common.p0_is_required", [
              i18n.t("warehouse.destination_type"),
            ]);
          },
        },
      ],
      toWarehoueOrFbaOrMcdId: [
        {
          required: true,
          trigger: ["change", "blur"],
          message: () => {
            return i18n.t("common.p0_is_required", [
              i18n.t("warehouse.warehouse_code"),
            ]);
          },
        },
      ],
      customerNo: [
        {
          required: true,
          trigger: ["change", "blur"],
          pattern: /^[A-Za-z0-9\-]{5,15}$/,
          message: i18n.t("warehouse.please_enter_the_correct_p0", [
            i18n.t("warehouse.customer_number"),
          ]),
        },
      ],
      from: {
        name: [
          {
            required: true,
            message: () =>
              i18n.t("common.p0_is_required", [i18n.t("warehouse.originate")]),
          },
          {
            trigger: ["change", "blur"],
            pattern: /^.{1,32}$/,
            message: i18n.t("common.p0_up_to_p1_characters", [
              i18n.t("logistics.originate"),
              32,
            ]),
          },
        ],
        address: [
          {
            required: true,
            whitespace: true,
            trigger: ["change", "blur"],
            validator: (rule, value) => {
              if (!value) {
                return Promise.reject(
                  i18n.t("common.p0_is_required", [
                    i18n.t("warehouse.detail_address"),
                  ])
                );
              }
              if (value && value?.length > 35) {
                return Promise.reject(
                  i18n.t("common.the_input_length_is_too_long")
                );
              }

              return Promise.resolve();
            },
          },
        ],
        postCode: [
          {
            required: true,
            whitespace: true,
            trigger: ["change", "blur"],
            validator: (rule, value) => {
          if (!value||value.trim().length == 0) {
            return Promise.reject(i18n.t("common.p0_is_required", [i18n.t("common.postcode")]));
          }

          if (value && value?.trim().length < 5) {
            return Promise.reject(i18n.t("common.p0_please_enter_at_least_p1_digits", [i18n.t("common.postcode"),5]));
          }
          return Promise.resolve();
        },
          },
        ],
        countryId: [
          {
            required: true,
            whitespace: true,
            trigger: ["change", "blur"],
            message: () => {
              return i18n.t("common.p0_is_not_complete", [
                i18n.t("common.country"),
              ]);
            },
          },
        ],
        provinceId: [
          {
            required: true,
            whitespace: true,
            validator: (rule, value) => {
              if (
                dropdownData.provincesFrom.length > 0 &&
                !state.from.provinceId
              ) {
                return Promise.reject();
              }
              return Promise.resolve();
            },
            trigger: ["change", "blur"],
            message: () => {
              return i18n.t("common.p0_is_not_complete", [
                i18n.t("common.province"),
              ]);
            },
          },
        ],
        cityId: [
          {
            required: true,
            whitespace: true,
            validator: (rule, value) => {
              if (dropdownData.citysFrom.length > 0 && !state.from.cityId) {
                return Promise.reject();
              }
              return Promise.resolve();
            },
            trigger: ["change", "blur"],
            message: () => {
              return i18n.t("common.p0_is_not_complete", [
                i18n.t("common.city"),
              ]);
            },
          },
        ],
      },
      toPersonal: {
        name: [
          {
            required: true,
            message: () =>
              i18n.t("common.p0_is_required", [i18n.t("warehouse.recipients")]),
          },
          {
            trigger: ["change", "blur"],
            pattern: /^.{1,32}$/,
            message: i18n.t("common.p0_up_to_p1_characters", [
              i18n.t("logistics.recipients"),
              32,
            ]),
          },
        ],
        countryId: [
          {
            required: true,
            whitespace: true,
            trigger: ["change", "blur"],
            message: () => {
              return i18n.t("common.p0_is_not_complete", [
                i18n.t("common.country"),
              ]);
            },
          },
        ],
        provinceId: [
          {
            required: true,
            whitespace: true,
            validator: (rule, value) => {
              if (
                dropdownData.provinces.length > 0 &&
                !state.toPersonal.provinceId
              ) {
                return Promise.reject();
              }
              return Promise.resolve();
            },
            trigger: ["change", "blur"],
            message: () => {
              return i18n.t("common.p0_is_not_complete", [
                i18n.t("common.province"),
              ]);
            },
          },
        ],
        cityId: [
          {
            required: true,
            whitespace: true,
            validator: (rule, value) => {
              if (dropdownData.citys.length > 0 && !state.toPersonal.cityId) {
                return Promise.reject();
              }
              return Promise.resolve();
            },
            trigger: ["change", "blur"],
            message: () => {
              return i18n.t("common.p0_is_not_complete", [
                i18n.t("common.city"),
              ]);
            },
          },
        ],
        //取消区县的必填
        // countyId: [{
        //   required: true,
        //   whitespace: true,
        //   validator: (rule, value) => {
        //     if (dropdownData.countys.length > 0 && !state.toPersonal.countyId) {
        //       return Promise.reject();
        //     }
        //     return Promise.resolve();
        //   },
        //   trigger: ['change', 'blur'],
        //   message: () => {
        //     return i18n.t("common.p0_is_not_complete", [i18n.t("common.county")])
        //   }
        // }],
        telephone: [
          {
            required: true,
            whitespace: true,
            trigger: ["change", "blur"],
            validator: (rule, value) => {
              if (!state.toPersonal.telephoneCountryId) {
                return Promise.reject(
                  i18n.t("common.p0_is_required", [
                    i18n.t("logistics.area_code"),
                  ])
                );
              }
              if (!value) {
                return Promise.reject(
                  i18n.t("common.p0_is_required", [i18n.t("warehouse.phone")])
                );
              }
              let selectCountry = dropdownData.phoneCountryList.find(
                (item) => item.id === state.toPersonal.telephoneCountryId
              );
              let countryIos2Code = selectCountry?.ioS2 ?? null;
              if (
                verifyMobilePhoneNumber(
                  countryIos2Code,
                  (selectCountry?.telCode ?? "") + value
                )
              ) {
                return Promise.resolve();
              } else {
                return Promise.reject(
                  i18n.t("common.p0_incorrect_format", [
                    i18n.t("warehouse.phone"),
                  ])
                );
              }
            },
          },
        ],

        address: [
          {
            required: true,
            whitespace: true,
            trigger: ["change", "blur"],
            validator: (rule, value) => {
              if (!value) {
                return Promise.reject(
                  i18n.t("common.p0_is_required", [
                    i18n.t("warehouse.detail_address"),
                  ])
                );
              }
              if (value && value?.length > 35) {
                return Promise.reject(
                  i18n.t("common.the_input_length_is_too_long")
                );
              }

              return Promise.resolve();
            },
          },
        ],
        postCode: [
          {
            required: true,
            whitespace: true,
            trigger: ["change", "blur"],
            validator: (rule, value) => {
          if (!value||value.trim().length == 0) {
            return Promise.reject(i18n.t("common.p0_is_required", [i18n.t("common.postcode")]));
          }

          if (value && value?.trim().length < 5) {
            return Promise.reject(i18n.t("common.p0_please_enter_at_least_p1_digits", [i18n.t("common.postcode"),5]));
          }
          return Promise.resolve();
        },
          },
        ],
      },
      tranPlanCharacteristics: [
        {
          required: true,
          whitespace: true,
          type: "array",
          trigger: ["change", "blur"],
          message: () => {
            return i18n.t("common.p0_is_required", [
              i18n.t("warehouse.transport_characteristics"),
            ]);
          },
        },
      ],
    };

    const handleCreate = () => {
      state.loading = true;
      refForm.value
        .validate()
        .then(() => {
          if (!state.list || state.list.length == 0) {
            message.warning(i18n.t("warehouse.box_is_not_added"));
            state.loading = false;
            return;
          }

          state.details = cloneDeep(state.list);
          // state.details?.forEach(x => x.weight = kgToG(x.weight));

          if (state.destinationType != destinationTypeEnum.OtherAddress) {
            state.toPersonal = null;
          }

          if (state.orderId) {
            return update({ ...state }).then(({ result }) => {
              message.success(i18n.t("common.succeed"));
              state.loading = false;
              delVisitedRoute(router.currentRoute.value);
              router.push({
                name: "print_waybill_confirm",
                params: { id: state.orderId },
              });
            });
          } else {
            return create({ ...state }).then(({ result }) => {
              if (result > 0) {
                message.success(i18n.t("common.succeed"));
                delVisitedRoute(router.currentRoute.value);
                router.push({
                  name: "print_waybill_confirm",
                  params: { id: result },
                });
              }
              state.loading = false;
            });
          }
        })
        .catch((error) => {
          state.loading = false;
          if (error?.errorFields?.length > 0) {
            refForm.value?.scrollToField(
              error?.errorFields[0]?.name?.toString()
            );
          }
        });
    };

    const columns = [
      {
        title: () => i18n.t("warehouse.serial_number"),
        customRender: ({ text, record, index }) => {
          return index + 1;
        },
        width: 70,
      },
      {
        title: () => i18n.t("warehouse.actual_weight"),
        slots: {
          customRender: "weight",
        },
        width: 80,
      },
      {
        title: () => i18n.t("warehouse.size"),
        slots: {
          customRender: "size",
        },
        width: 120,
      },
      {
        title: () => i18n.t("warehouse.girth"),
        slots: {
          customRender: "girth",
        },
        width: 120,
      },
      {
        title: () => i18n.t("warehouse.volume"),
        slots: {
          customRender: "volume",
        },
        width: 120,
      },
      {
        title: "",
        fixed: "right",
        slots: {
          customRender: "operate",
        },
        width: 100,
      },
    ];

    const handleChangeDestinationType = (isDeleteToPersonal = true) => {
      state.toWarehoueOrFbaOrMcdId = null;
      state.showRecipients = null;
      state.showRecipientsCompany = null;
      state.showRecipientsAddress = null;

      if (state.destinationType == destinationTypeEnum.FBA) {
        //亚马逊
        if (
          !dropdownData.fbaWarehouseCodes ||
          dropdownData.fbaWarehouseCodes.length == 0
        ) {
          hanldeGetFbaTargetWarehouseInfos();
        } else {
          dropdownData.warehouseCodes = dropdownData.fbaWarehouseCodes;
        }
      } else if (state.destinationType == destinationTypeEnum.SeWarehouse) {
        //赛易
        if (
          !dropdownData.seWarehouseCodes ||
          dropdownData.seWarehouseCodes.length == 0
        ) {
          hanldeGetSeTargetWarehouseInfos();
        } else {
          dropdownData.warehouseCodes = dropdownData.seWarehouseCodes;
        }
      } else if (state.destinationType == destinationTypeEnum.OtherAddress) {
        if (!dropdownData.countrys || dropdownData.countrys.length == 0) {
          funcGetCountrys();
        }
      }

      if (
        !dropdownData.commonAddress ||
        dropdownData.commonAddress.length == 0
      ) {
        hanldeGetCommonAddress();
      }

      if (!dropdownData.countrysForm || dropdownData.countrysForm.length == 0) {
        funcGetCountrysForm();
      }

      if (isDeleteToPersonal) {
        state.toPersonal = _getToPersonal();
      }
    };

    const _getToPersonal = () => {
      return {
        //目的地址
        companyName: null,
        telephone: null,
        name: null,
        countryId: null,
        provinceId: null,
        cityId: null,
        countyId: null,
        address: null,
        address2: null,
        postCode: null,
        id: null,
      };
    };

    //获得se 仓库代码
    const hanldeGetSeTargetWarehouseInfos = () => {
      dropdownData.warehouseCodesLoading = true;
      getSeTargetWarehouseInfos()
        .then(({ result }) => {
          if (result) {
            dropdownData.seWarehouseCodes = result;
            dropdownData.warehouseCodes = result;
            dropdownData.warehouseCodesLoading = false;
          }
        })
        .catch(() => {
          dropdownData.warehouseCodesLoading = false;
        });
    };

    //获得fba 仓库代码
    const hanldeGetFbaTargetWarehouseInfos = () => {
      dropdownData.warehouseCodesLoading = true;
      getFbaTargetWarehouseInfos()
        .then(({ result }) => {
          if (result) {
            dropdownData.fbaWarehouseCodes = result;
            dropdownData.warehouseCodes = result;
            dropdownData.warehouseCodesLoading = false;
          }
        })
        .catch(() => {
          dropdownData.warehouseCodesLoading = false;
        });
    };

    //仓库代码 选择变化
    const handleChangeWarehouseCodes = (value, option) => {
      if (!value) {
        state.showRecipients = null;
        state.showRecipientsCompany = null;
        state.showRecipientsAddress = null;
        return;
      }

      if (state.destinationType == destinationTypeEnum.SeWarehouse) {
        state.showRecipients = "SALEASY";
        state.showRecipientsCompany = option.code;
      } else if (state.destinationType == destinationTypeEnum.FBA) {
        state.showRecipients = "AMAZON.COM SERVICES INC";
        state.showRecipientsCompany = option.code;
      }
      state.showRecipientsAddress = option.info;
    };

    const getAddress = (address) => {
      return getAddressByLanguageV2(address, getters.language);
    };

    const hanldeGetDetails = (orderId) => {
      state.pageLoading = true;
      getDetails(orderId)
        .then(({ result }) => {
          if (result) {
            Object.assign(state, result);
            state.toPersonal = result?.to;
            // 单独处理手机号码
            state.toPersonal.telephone = state.toPersonal.notCombineTelCode;
            result?.details.forEach((x, index) => (x.key = index + 1));
            state.list = result.details;
            handleChangeDestinationType(false);
            if (
              result?.to?.id &&
              state.destinationType != destinationTypeEnum.OtherAddress
            ) {
              state.toWarehoueOrFbaOrMcdId = result?.to?.id;
              let optionV = {
                code: result?.to?.no,
                info: result?.to,
              };
              handleChangeWarehouseCodes(state.toWarehoueOrFbaOrMcdId, optionV);
            }
          }
        })
        .catch(() => {
          state.pageLoading = false;
        });
    };

    const postCodeQueryData = reactive({
      visible: false,
      visibleForm: false,
    });

    const handlePostCodeQuery = (type) => {
      if (type == "form") {
        postCodeQueryData.visibleForm = true;
      } else if (type == "toPersonal") {
        postCodeQueryData.visible = true;
      }
    };

    const handleOnPostQuerySelected = (record, type) => {
      if (type === "from") {
        if (state.from) {
          state.from.countryId = record.countryId;
          state.from.provinceId = record.provinceId;
          state.from.cityId = record.cityId;
          state.from.countyId = record.countyId;
          state.from.postCode = record.postCode;
          if (record.address) {
            state.from.address = record.address;
          }
          funcGetCountrysForm(type);
          refForm.value.clearValidate([
            ["from", "postCode"],
            ["from", "countryId"],
            ["from", "provinceId"],
            ["from", "cityId"],
          ]);
        }
      } else if (type === "toPersonal") {
        if (state.toPersonal) {
          state.toPersonal.countryId = record.countryId;
          state.toPersonal.provinceId = record.provinceId;
          state.toPersonal.cityId = record.cityId;
          state.toPersonal.countyId = record.countyId;
          state.toPersonal.postCode = record.postCode;
          if (record.address) {
            state.toPersonal.address = record.address;
          }
          funcGetCountrys(type);
          refForm.value.clearValidate([
            ["toPersonal", "postCode"],
            ["toPersonal", "countryId"],
            ["toPersonal", "provinceId"],
            ["toPersonal", "cityId"],
          ]);
        }
      }
    };

    const init = () => {
      state.orderId = route.params?.id;
      let fromId = route.query?.fromId;
      if (state.orderId || fromId) {
        hanldeGetDetails(state.orderId || fromId);
      } else {
        //目的地类型默认是赛易仓库 destinationType=1,
        if (state.destinationType == destinationTypeEnum.SeWarehouse) {
          hanldeGetSeTargetWarehouseInfos();
        } else if (state.destinationType == destinationTypeEnum.FBA) {
          hanldeGetFbaTargetWarehouseInfos();
        }

        if (
          !dropdownData.commonAddress ||
          dropdownData.commonAddress.length == 0
        ) {
          hanldeGetCommonAddress();
        }

        if (
          !dropdownData.countrysForm ||
          dropdownData.countrysForm.length == 0
        ) {
          funcGetCountrysForm();
        }
      }
      getCountriesList();
    };

    watch(
      () => state.formId,
      (newVal, oldVal) => {
        init();
      }
    );
    onActivated(() => {
      state.formId = route.query?.fromId;
    });

    onMounted(() => {
      init();
    });

    return {
      columns,
      state,
      rules,
      dropdownData,
      addBoxModal,
      refForm,
      transportCharacteristicsEnum,
      destinationTypeEnum,

      roundNumber,
      getLanguageName,
      getAddress,
      gToKg,

      handleChangeDestinationType,
      handleChangeWarehouseCodes,

      handleCreate,
      handleAddBox,
      handleRemove,
      handleShowModal,

      handleChangeSelectCommonAddressForm,
      handleChangeCountryForm,
      handleChangeProvincesForm,
      handleChangeCityForm,
      funcGetCountrysForm,
      funcGetProvincesForm,
      funcGetCitysForm,
      funcGetCountysForm,
      handleSaveCommonAddressForm,

      handleChangeSelectCommonAddress,
      handleChangeCountry,
      handleChangeProvinces,
      handleChangeCity,
      funcGetCountrys,
      funcGetProvinces,
      funcGetCitys,
      funcGetCountys,
      handleSaveCommonAddress,

      postCodeQueryData,
      handlePostCodeQuery,
      handleOnPostQuerySelected,
      handleCountryCodeChange,
    };
  },
};
</script>

<style lang="less" scoped></style>
