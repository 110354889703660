<template>
  <div class="address mt-2">
    <a-form ref="refForm"
         
            :model="data">
      <a-row>
        <a-col :sm="{ span: 24 }"
               :xl="{ span: 8 }"
               :xxl="{ span: 6 }">
          <a-form-item v-if="isShowSelectCommonAddress"
                       :label-col="{sm: {span: 4},md: {span: 3},xl: {span: 6},xxl: {span: 6},}"
                       :label="$t('warehouse.common_address')">
            <a-select v-model:value="data.selectedCommonAddress"
                      @change="changeSelectCommonAddress"
                      class="input-w"
                      :allowClear="true"
                      :placeholder="$t('warehouse.selected_common_address')">
              <a-select-option v-for="item in state.commonAddress"
                               :key="item.id"
                               :value="item.id"
                               :countryId="item.countryId"
                               :provinceId="item.provinceId"
                               :cityId="item.cityId"
                               :countyId="item.countyId"
                               :address="item.address"
                               :postCode="item.postCode"
                               :commonAddressName="item.contactName"
                               :title="item.contactName">
                {{item.contactName}}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :sm="{ span: 24 }"
               :xl="{ span: 8 }"
               :xxl="{ span: 6 }">
          <a-form-item :label="$t('warehouse.recipients')"
                       :label-col="{sm: {span: 4},md: {span: 3},  xl: {span: 10},xxl: {span: 10},}"
                       name="commonAddressName">
            <a-input v-model:value="data.commonAddressName"
                     class="input-w" />
          </a-form-item>
        </a-col>
      </a-row>

      <a-row>
        <a-col :sm="{ span: 24 }"
               :xl="{ span: 8 }"
               :xxl="{ span: 6 }">
          <a-form-item :label-col="{sm: {span: 4},md: {span: 3},xl: {span: 6},xxl: {span: 6},}"
                       :label="$t('warehouse.recipient_company')">
            <a-input v-model:value="data.companyName"
                     class="input-w" />
          </a-form-item>
        </a-col>

        <a-col :sm="{ span: 24 }"
               :xl="{ span: 8 }"
               :xxl="{ span: 6 }">
          <a-form-item :label="$t('warehouse.phone')"
                       :label-col="{sm: {span: 4},md: {span: 3},  xl: {span: 10},xxl: {span: 10},}"
                       name="telephone">
            <a-input v-model:value="data.telephone"
                     class="input-w" />
          </a-form-item>
        </a-col>
      </a-row>

      <a-row>
        <a-col :span="24"
               :xxl="12">
          <a-form-item :label-col="{sm: {span: 4},md: {span: 3},xl: {span: 2},xxl: {span: 3},}"
                       :wrapper-col="{sm: {span: 20},md: {span: 21},xl: {span: 22},xxl: {span: 18},}"
                       name="sendGoodsAddress"
                       ref="refSendGoodsAddress"
                       :autoLink="false">
            <template #label>
              {{$t('warehouse.receiving_address')}}
            </template>
            <a-select v-model:value="data.selectedCountrys"
                      :allowClear="true"
                      show-search
                      optionFilterProp="search-key"
                      @change="handleChangeCountry"
                      @blur="() => {$refs.refSendGoodsAddress.onFieldBlur()}"
                      class="mr-2 mb-1"
                      style="max-width:150px;"
                      :placeholder="$t('common.country')">
              <a-select-option v-for="item in state.countrys"
                               :key="item.id"
                               :title="getLanguageName(item)"
                               :search-key="item.cnName + item.enName">
                {{getLanguageName(item)}}
              </a-select-option>
            </a-select>

            <a-select @blur="() => {$refs.refSendGoodsAddress.onFieldBlur()}"
                      v-model:value="data.selectedProvinces"
                      @change="handleChangeProvinces"
                      name="selectedProvinces"
                      class="mr-2 mb-1 "
                      style="max-width:150px;"
                      :allowClear="true"
                      show-search
                      optionFilterProp="search-key"
                      :placeholder="$t('common.province')">
              <a-select-option v-for="item in state.provinces"
                               :title="getLanguageName(item)"
                               :key="item.id"
                               :search-key="item.cnName + item.enName">
                {{getLanguageName(item)}}
              </a-select-option>
            </a-select>

            <a-select @blur="() => {$refs.refSendGoodsAddress.onFieldBlur()}"
                      v-model:value="data.selectedCity"
                      @change="handleChangeCity"
                      class="mr-2 mb-1 "
                      style="max-width:150px;"
                      :allowClear="true"
                      show-search
                      optionFilterProp="search-key"
                      :placeholder="$t('common.city')">
              <a-select-option v-for="item in state.citys"
                               :title="getLanguageName(item)"
                               :key="item.id"
                               :search-key="item.cnName + item.enName">
                {{getLanguageName(item)}}
              </a-select-option>
            </a-select>

            <a-select v-if="state.countys.length > 0 && data.selectedCity"
                      @blur="() => {$refs.refSendGoodsAddress.onFieldBlur()}"
                      v-model:value="data.selectedCounty"
                      class="mr-2 "
                      style="max-width:150px;"
                      :allowClear="true"
                      show-search
                      optionFilterProp="search-key"
                      :placeholder="$t('common.county')">
              <a-select-option v-for="item in state.countys"
                               :title="getLanguageName(item)"
                               :key="item.id"
                               :search-key="item.cnName + item.enName">
                {{getLanguageName(item)}}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>

      <a-row>
        <a-col :sm="{ span: 24 }"
               :xl="{ span: 8 }"
               :xxl="{ span: 6 }">
          <a-form-item name="detailedAddress"
                       :label-col="{sm: {span: 4},md: {span: 3},   xl: {span: 6},xxl: {span: 6},}"
                       :label="$t('warehouse.detail_address')">
            <a-input v-model:value="data.detailedAddress"
                     class="input-w" />
          </a-form-item>
        </a-col>
        <a-col :sm="{ span: 24 }"
               :xl="{ span: 8 }"
               :xxl="{ span: 6 }">
          <a-form-item :label="$t('common.postcode')"
                       :label-col="{sm: {span: 4},md: {span: 3},  xl: {span: 10},xxl: {span: 10},}"
                       name="postCode">
            <a-input v-model:value="data.postCode"
                     class="input-w" />
          </a-form-item>
        </a-col>
      </a-row>

    </a-form>
  </div>

  <!-- <div>
    <a-row>
      <a-col>
        <a-button type="primary"
                  class="mr-5 mb-2"
                  size="small"
                  @click="handleSaveCommonAddress"
                  :loading="state.buttonSaveCommonAddressLoading"
                  v-if="isShowButtonSaveCommon&&!data.selectedCommonAddress"
                  ghost>{{$t('warehouse.save_as_common_address')}}</a-button>
      </a-col>
      <a-col>
        <a-button type="primary"
                  class=" mb-2"
                  size="small"
                  :loading="state.buttonSaveCommonAddressLoading"
                  @click="handleSaveCommonAddress"
                  v-show="isShowButtonSaveCommon&&data.selectedCommonAddress"
                  ghost>{{$t('warehouse.refresh_address')}}</a-button>
      </a-col>
    </a-row>
  </div> -->

</template>

<script>
import { reactive, toRefs, onMounted, ref, computed, watch, nextTick } from "vue";
import {
  Row, Col, Input,
  Select, Button, Form, Modal, message
} from "ant-design-vue";
import { useI18n } from "vue-i18n/index";
import {
  getCommonAddress, getCountrys, getProvinces, getCitys, getCountys,
  createCommonAddress, updateCommonAddress
} from "../../api/modules/common/index";
import { getName } from "../../utils/general";
import { useStore } from "vuex";

export default ({
  //name: "Address",
  components: {
    ARow: Row,
    ACol: Col,
    AInput: Input,
    ASelect: Select,
    AButton: Button,
    ASelectOption: Select.Option,
    AFormItem: Form.Item,
    AForm: Form,
    AModal: Modal,
  },
  props: {
    isShowButtonSaveCommon: {
      type: Boolean,
      default: true,
      required: false
    },
    isShowSelectCommonAddress: {
      type: Boolean,
      default: false,
      required: false
    },
    isShipAddress: {//true:文字是发货,false:文字收货
      type: Boolean,
      default: true,
      required: false
    },
    addressData: {
      type: Object,
      default: function () {
        return {
          inputAddressAlias: "",
          selectedCommonAddress: null,
          commonAddressName: "",//发自
          selectedCountrys: null,
          selectedProvinces: null,
          selectedCity: null,
          selectedCounty: null,
          detailedAddress: "",
          postCode: "",
          companyName: null,
          telephone: null,
        }
      },
      required: false
    },
  },
  setup (props, { emit }) {

    const refInputCommonAddress = ref();
    const refForm = ref();
    const refSendGoodsAddress = ref();
    const vueI18n = useI18n({ useScope: "global" });
    const { getters } = useStore();

    const state = reactive({
      buttonSaveCommonAddressLoading: false,
      commonAddress: [],
      countrys: [],
      provinces: [],
      citys: [],
      countys: []
    })
    const data = reactive({});

    watch(() => props.addressData, (newV, oldV) => {
      Object.assign(data, newV || {});
      nextTick(() => {
        funcGetProvinces();
        funcGetCitys();
        funcGetCountys();
      })
    }, {
      immediate: true, deep: true
    });

    const handleSaveCommonAddress = () => {
      refForm.value
        .validate()
        .then(() => {
          let par = {
            "id": data.selectedCommonAddress,
            "contactName": data.commonAddressName,//发自//收件人
            "countryId": data.selectedCountrys,
            "provinceId": data.selectedProvinces,
            "cityId": data.selectedCity,
            "countyId": data.selectedCounty,
            "address": data.detailedAddress,
            "postCode": data.postCode,
            "companyName": data.companyName,
            "telephone": data.telephone,
          }

          state.buttonSaveCommonAddressLoading = true;
          if (par.id) {
            return updateCommonAddress(par).then(() => {
              message.success(vueI18n.t("common.succeed"));
              hanldeGetCommonAddress();
            })
          } else {
            return createCommonAddress(par).then((res) => {
              message.success(vueI18n.t("common.succeed"));
              hanldeGetCommonAddress().then(() => {
                data.selectedCommonAddress = res.result;
              });
            })
          }

        })
        .catch(error => {
          //
        }).finally(() => {
          state.buttonSaveCommonAddressLoading = false;
        });;

    };

    const hanldeGetCommonAddress = async () => {
      getCommonAddress().then((res) => {
        state.commonAddress = res.result;
      })
    }

    const funcGetCountrys = async () => {
      getCountrys().then((res) => {
        state.countrys = res.result;
      })
    }

    const handleChangeCountry = async () => {
      data.selectedProvinces = null
      data.selectedCity = null
      data.selectedCounty = null
      state.countys = [];
      state.citys = [];
      funcGetProvinces();
    }

    const funcGetProvinces = () => {
      if (data.selectedCountrys) {
        getProvinces({ countryId: data.selectedCountrys }).then((res) => {
          state.provinces = res.result;
        })
      } else {
        state.provinces = [];
      }
    }

    const handleChangeProvinces = async () => {
      data.selectedCity = null
      data.selectedCounty = null
      state.countys = [];
      funcGetCitys();
    }

    const funcGetCitys = () => {
      if (data.selectedProvinces) {
        getCitys({ provinceId: data.selectedProvinces }).then((res) => {
          state.citys = res.result;
        })
      } else {
        state.citys = [];
      }
    }

    const handleChangeCity = async () => {
      data.selectedCounty = null
      funcGetCountys();
    }

    const funcGetCountys = async () => {
      if (data.selectedCity) {
        getCountys({ cityId: data.selectedCity }).then((res) => {
          state.countys = res.result;
        })
      } else {
        state.countys = [];
      }
    }

    const getLanguageName = (item) => {
      return getName(item, getters.language);
    }

    const changeSelectCommonAddress = (value, option) => {
      if (option?.countryId) {
        data.selectedCountrys = option.countryId;
        data.commonAddressName = option.commonAddressName;
      } else {
        return
      }

      if (option.provinceId) {
        handleChangeCountry(option.countryId)
        data.selectedProvinces = option.provinceId;
      }

      if (option.cityId) {
        handleChangeProvinces(option.provinceId);
        data.selectedCity = option.cityId;
        if (option.countyId) {
          handleChangeCity(option.cityId);
          data.selectedCounty = option.countyId;
        }
      }

      if (option.address) {
        data.detailedAddress = option.address;
      }
      if (option.postCode) {
        data.postCode = option.postCode;
      }
    }

    let validateAddress = async (rule, value) => {
      if (state.countys.length > 0 && !data.selectedCounty) {
        return Promise.reject();
      }

      if (state.citys.length > 0 && !data.selectedCity) {
        return Promise.reject();
      }

      if (state.provinces.length > 0 && !data.selectedProvinces) {
        return Promise.reject();
      }

      if (!data.selectedCountrys) {
        return Promise.reject();
      }
      return Promise.resolve();
    };

    //获得地址信息(包括id,中英文),用于组合展示.
    const getAddressInfo = () => {
      let address = {};
      address.address = data.detailedAddress;
      address.postCode = data.postCode;
      address.name = data.commonAddressName;
      if (data.selectedCountrys && state.countrys) {
        let country = state.countrys.find(x => x.id == data.selectedCountrys);
        address.countryId = country.id;
        address.countryCnName = country.cnName;
        address.countryEnName = country.enName;

        if (data.selectedProvinces && state.provinces) {
          let province = state.provinces.find(x => x.id == data.selectedProvinces);
          address.provinceId = province.id;
          address.provinceCnName = province.cnName;
          address.provinceEnName = province.enName;
          if (data.selectedCity && state.citys) {
            let city = state.citys.find(x => x.id == data.selectedCity);
            address.cityId = city.id;
            address.cityCnName = city.cnName;
            address.cityEnName = city.enName;

            if (data.selectedCounty && state.countys) {
              let county = state.countys.find(x => x.id == data.selectedCounty);
              address.countyId = county.id;
              address.countyCnName = county.cnName;
              address.countyEnName = county.enName;
            }
          }
        }
      }
      Object.assign(data, address);
      return data;
    }

    // const rules = {
    //   sendGoodsAddress: [{
    //     required: true,
    //     validator: validateAddress,
    //     whitespace: true,
    //     trigger: ['change', 'blur'],
    //     message: () => {
    //       // return vueI18n.t("common.p0_is_not_complete", [vueI18n.t("warehouse.send_goods_address")])
    //       return vueI18n.t("common.p0_is_not_complete", [vueI18n.t("warehouse.address")])
    //     }
    //   }],
    //   detailedAddress: [{
    //     required: true,
    //     whitespace: true,
    //     trigger: ['change', 'blur'],
    //     message: () => {
    //       return vueI18n.t("common.p0_is_required", [vueI18n.t("warehouse.detail_address")])
    //     }
    //   }],
    //   postCode: [{
    //     required: true,
    //     whitespace: true,
    //     trigger: ['change', 'blur'],
    //     message: () => {
    //       return vueI18n.t("common.p0_is_required", [vueI18n.t("common.postcode")])
    //     }
    //   }],
    //   inputAddressAlias: [{
    //     required: true,
    //     whitespace: true,
    //     trigger: ['change', 'blur'],
    //     message: () => {
    //       return vueI18n.t("common.p0_is_required", [vueI18n.t("warehouse.alias")])
    //     }
    //   }],
    //   commonAddressName: [{
    //     required: true,
    //     whitespace: true,
    //     trigger: ['change', 'blur'],
    //     message: () => {
    //       return vueI18n.t("common.p0_is_required", [vueI18n.t("warehouse.originate")])
    //     }
    //   }],
    // };

    onMounted(async () => {
      if (props.isShowSelectCommonAddress) {
        hanldeGetCommonAddress();
      }
      funcGetCountrys();
    });

    return {
      refForm,
      refInputCommonAddress,
      refSendGoodsAddress,
      state,
      data,
      props,

      handleChangeCountry,
      handleChangeProvinces,
      handleChangeCity,
      getLanguageName,
      changeSelectCommonAddress,
      handleSaveCommonAddress,
      funcGetProvinces,
      funcGetCitys,
      funcGetCountys,
      getAddressInfo
    }
  }
})
</script>
<style lang="less" scoped>
@media (max-width: 450px) {
  .input-w {
    width: 220px;
  }
}
@media (min-width: 451px) {
  .input-w {
    width: 350px;
  }
}
</style>